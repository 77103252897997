import React, { useState, useRef, useEffect } from 'react';
import { Link, RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import { DefaultProps, PropTypes } from '../../../../core/Utils/SitecoreFields';

const MenuBuilder = (menuItems) => {
  // Tracking expanded submenu
  const [expand, setExpand] = useState(-1);
  const menuRef = useRef(null);
  
  const [anchorsInSight, setAnchorsInSight] = useState([]);
  const [jumpFinishedTimer, setJumpFinishedTimer] = useState(0);

  const [pathname, setPathname] = useState(null)

  // update pathname on client render
  useEffect(() => {
    setPathname(window.location.pathname)
  }, [])

  // Submenu open/close
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setExpand(-1);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [expand]);


  useEffect(() => {
    window.cochlearsoc = {};

    // Page scrolling tracker
    let anchorsToTrack = [];
    menuItems.forEach(item => {
      item.anchors.forEach(i => {
        if (i.anchor?.value) {
          const anchorValue = i.anchor.value.includes('#') ? i.anchor.value.split('#')[1] : i.anchor.value;
          if (!anchorsToTrack.includes(anchorValue)) {
            anchorsToTrack.push(anchorValue)
          }
        }
      });
    })

    const getInSightEls = () => {
      // Anchor tracker
      const inSightAnchors = [];
      for (let looper = 0; looper < anchorsToTrack.length; looper++) {
        const target = document.getElementById(anchorsToTrack[looper]);
        const viewPortHeight = window.innerHeight;

        if (target) {
          const rect = target.getBoundingClientRect();
          if (viewPortHeight - rect.top > 400 && !target.classList.contains('section-in-sight')) {
            target.classList.add('section-in-sight');
          } else if (rect.top <= 80) {
            inSightAnchors.push(anchorsToTrack[looper]);
          }
        }
      }
      setAnchorsInSight(inSightAnchors);
    }

    let throttle = 0;
    let delayCompute = 0;
    const anchorTracker = () => {
      if ((new Date().valueOf() - throttle) < 100) {
        clearTimeout(delayCompute);
        delayCompute = setTimeout(() => {
          getInSightEls();
        }, 150);
        return false;
      } else {
        throttle = new Date().valueOf();
        getInSightEls();
      }


    };

    document.addEventListener('scroll', anchorTracker);

    return () => {
      document.removeEventListener('scroll', anchorTracker)
    }
  }, []);


  const scrollIntoView = (anchorVal, e) => {
    const anchor = anchorVal.includes('#') ? anchorVal.split('#')[1] : anchorVal;
    const target = document.getElementById(anchor);
    if (target) {
      document.body.style.overflow = 'visible';
      window.cochlearsoc.jumping = true; // avoid conflicts with some unskippable section
      clearTimeout(jumpFinishedTimer);
      const timerId = setTimeout(() => {
        window.cochlearsoc.jumping = false;
      }, 1000);

      setJumpFinishedTimer(timerId);

      target.scrollIntoView();
    }
  }

  return (
    <ul ref={menuRef} className='lv-1'>
      {menuItems.map((item, index) => (
        <li key={item.displayText.value} className={`${expand == index ? 'open' : ''}${pathname?.includes('/' + item.displayText.value.toLowerCase()) ? ' active' : ''}`}>
          <div className='lv-1-cta d-flex flex-row justify-content-between position-relative w-100'>
            
            <a className='lv-1-title' href={item.page.value}>
              {item.displayText.value}
            </a>

            <span onClick={() => { setExpand(expand == index ? -1 : index) }} className='lv-1-caret'></span>
          </div>
          {item.anchors && (
            <ul className='lv-2'>
              {item.anchors.map((anchor) => (
                <li className={anchorsInSight.includes(anchor.anchor?.value.includes('#') ? anchor.anchor?.value.split('#')[1] : anchor.anchor?.value) ? 'active' : ''} key={anchor.displayText.value}>
                  <a onClick={(e) => { scrollIntoView(anchor.anchor?.value, e) }} href={anchor.anchor?.value}>{anchor.displayText.value}</a>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
}

const SocHeader = ({ fields }) => {
  const [menuState, setMenuState] = useState(false);
  const [actionState, setActionState] = useState(false);

  // Tracking page scorll direction
  const [scrolled, setScrolled] = useState(0);
  const [scrollUp, setScrollUp] = useState(false);

  useEffect(() => {
    let throttle = 0;

    if ((new Date().valueOf() - throttle) < 100) {
      return false;
    } else {
      throttle = new Date().valueOf();
    }

    const handleScrollDirection = (event) => {
      // Scroll direction
      if (window.scrollY - scrolled > 100) {
        setScrolled(window.scrollY);
        setScrollUp(true);
      } else if (window.scrollY - scrolled < -100) {
        setScrolled(window.scrollY);
        setScrollUp(false);
      }
    };

    if (window.innerWidth <= 768) {
      document.addEventListener('scroll', handleScrollDirection);
    }

    return () => {
      document.removeEventListener('scroll', handleScrollDirection);
    };
  }, [scrolled, scrollUp]);

  const handleMenuClick = (e) => {
    if (e.target.tagName.toLowerCase() === 'a') {
      setMenuState(false);
    }
  }

  return (
    <>
      <div className='loading-wrapper'>
      </div>

      <div className={`component soc-header ${scrollUp ? 'hide-up-m' : ''} ${fields['css classes']?.value}`}>
        <div className='container g-0'>
          <div className='content d-flex flex-row justify-content-between'>
            <div className='site-logo'>
              <div className='site-logo-content d-flex flex-row'>
                <h1 className='site-logo-title order-2'>
                  <span className='d-flex align-items-center'>GO <Image field={fields.data.item.logo.jss} /></span>
                  HEAR
                </h1>
                <p className='site-logo-sub-title order-1 text-end lh-1'>TAKE <br />ACTION <br />EARLY</p>
              </div>
            </div>

            <div className='header-links d-flex flex-row justify-content-end justify-content-md-between flex-grow-1'>
              <div className={`header-links-menu order-3 order-md-2 ${menuState ? 'opened' : ''}`}>
                <div className='header-links-menu-toggle d-flex d-md-none' onClick={() => setMenuState(!menuState)}>
                  <span className={`open ${menuState ? 'd-none' : 'd-block'}`}>
                    <span className="icon"></span>
                    MENU
                  </span>

                </div>

                <div onClick={handleMenuClick} className='header-links-menu-content'>
                  <div className='menu-control'>
                    <button className={`close`} onClick={() => setMenuState(!menuState)}><span className='d-none'>close</span></button>
                  </div>

                  {
                    MenuBuilder(fields.data.item.menuItems)
                  }
                </div>
              </div>

              <div className='take-action order-2 order-md-3'>
                <Link className='take-action-toggle' onClick={(e) => { e.preventDefault(); setActionState(!actionState) }} field={fields.data.item.takeAction.jss}>
                  {fields.data.item.takeAction.jss.value.text || 'TAKE ACTION'}
                </Link>

              </div>
            </div>
          </div>
        </div>

        <div className={`take-action-wrapper ${actionState ? 'active' : ''}`}>
          <div className={`take-action-content`}>
            <a className='take-action-close' onClick={() => setActionState(false)}>Close</a>
            <RichText tag="" field={fields.data.item.takeActionRTE} />
          </div>
        </div>
      </div>


    </>);
};

SocHeader.propTypes = PropTypes.SitecoreItem.inject(PropTypes.ContentBlock);
SocHeader.defaultProps = DefaultProps.SitecoreItem.inject(
  DefaultProps.ContentBlock,
);

export default SocHeader;
